/* VARIABLES */

:root {
    --logo: #ffffffe0;
    
    --primary: #3accb2;
    --primary-hover: #37d6ba;
    --primary-touch: #37d6b979;
    --primary-text: #f3f3f3;
    --primary-text-hover: #fff;
    --primary-glow: #2dd6b8;
    --primary-shadow: #0000005c;

    --secondary: #fff;
    --secondary-hover: #fff;
    --secondary-touch: rgba(255, 255, 255, 0.541);
    --secondary-text: #ef354f;
    --secondary-text-hover: #ef354f;
    --secondary-glow: #f33d56;
    --secondary-shadow: #00000028;
}

/* IMPORT DE LA FONT KOTORI ROSE */

@font-face {
    font-family: "Kotori Rose Bold";
    src: url("../src/kotori/KotoriRose-Bold.otf");
}

/* EFFETS */
/* 
.nlt-it-floats {
    box-shadow: 10px 10px 10px red !important;
} */


/* HEADER */

.nlt-title-header {
    font-family: "Kotori Rose Bold";
    text-align: left !important;
    font-size: 21px;
    text-decoration: none;
    line-height: 6px;
    font-weight: bolder;
    margin-bottom: 8px !important;
    color: #ffffff;
    box-shadow: -5px 32px 0px -5px #ffffff, 5px 32px 0px -5px #ffffff;
    cursor: pointer;
    transition-duration: 0.2s !important;
    display: inline-flex !important;
    align-items: center !important;
}

    .nlt-title-header:hover {
        box-shadow: -5px 26px 0px -5px #ffffff, 5px 26px 0px -5px #ffffff;
}

.nlt-header-logo {
    margin: -2px 0 0 0;
    padding: 0;
}

.nlt-title-header-label {
    margin-left: -24px !important;
}

.nlt-title-link {
    text-decoration: none;
    display: flex !important;
    align-content: center !important;
}

.nlt-button-header {
    padding: 16px 28px !important;
    text-decoration: none !important;
    text-transform: none !important;
    color: #ffffffe1 !important;
    font-size: 14px !important;
    background-color: #ffffff00 !important;
    padding: 7px 25px !important;
    box-shadow: 0px 0px 20px -6px #00000000 !important;
    transition: .2s ease !important;
}

    .nlt-button-header:hover {
        color: #ffffff !important;
        background-color: #ffffff13 !important;
        box-shadow: 0px 0px 20px -6px #0000005d !important;

}

.nlt-button-header-copied {
    padding: 16px 28px !important;
    text-decoration: none !important;
    text-transform: none !important;
    color: var(--primary) !important;
    font-size: 14px !important;
    background-color: #ffffff00 !important;
    padding: 7px 25px !important;
    box-shadow: 0px 0px 20px -6px #00000000 !important;
    transition: 1s ease !important;
    font-family: "Heebo", sans-serif !important;
    text-decoration: none !important;
    font-weight: 800 !important;
}

.nlt-full-team {
    margin-bottom: 24px;
}

.nlt-third {
    width: 100%;
    margin: 0 5px 0 5px;
}


/* BODY */

@media (min-width: 761px) {

    .nlt-container {
        padding: 0 180px;
        background-color: rgba(0, 0, 0, 0);
    }

    .nlt-container-white {
        padding: 0 180px;
        background-color: #fff;
        background-image: url('./assets/images/nlt_logo_3d.png') !important;
        background-size: 880px;
        background-position: right 0;
        background-repeat: no-repeat;
    }

    .nlt-container-primary {
        padding: 0 180px;
        margin-top: 4px;
        background-color: rgb(239, 53, 79);
    }

    .nlt-team-container {
        display: block;
    }

    .nlt-team-member {
        display: inline-grid;
        margin-bottom: 0 !important;
        width: 180px;
    }

    .nlt-team-member-name {
        color: #171923;
        line-height: 1;
        margin-bottom: 0;
        font-family: sans-serif;
        font-weight: 600;
        font-size: 16px;
        letter-spacing: 0.1px;
    }

    .nlt-team-member-title {
        color: var(--primary);
        font-weight: bolder;
        font-family: sans-serif;
        line-height: 24px;
        font-size: 17px;
    }

    .nlt-team-tile-member-title {
        color: var(--primary);
        font-weight: bolder;
        font-family: sans-serif;
        line-height: 24px;
        font-size: 16px;
    }

    .nlt-title {
        font-family: "Kotori Rose Bold";
        text-align: left !important;
        font-size: 38px;
        line-height: 45px;
        font-weight: bolder;
        color: #fff;
        margin-bottom: 32px !important;
    }

    .nlt-title-black {
        color: #161616;
        font-family: "Kotori Rose Bold";
        text-align: left !important;
        font-size: 38px;
        line-height: 45px;
        font-weight: bolder;
        margin-bottom: 32px !important;
    }

    .nlt-content {
        text-align: left !important;
        font-size: 16px;
        line-height: 20px;
        font-weight: 500;
        letter-spacing: 0.3px;
        color: white !important;
        margin-bottom: 42px !important;
    }

    .nlt-content-black {
        color: #161616 !important;
        text-align: left !important;
        font-size: 16px;
        line-height: 20px;
        font-weight: 500;
        letter-spacing: 0.3px;
        margin-bottom: 42px !important;
    }

    .nlt-align {
        text-align: left !important;
    }

    .nlt-button {
        text-align: left !important;
        text-transform: capitalize !important;
        font-size: 16px!important;
        color: #161822 !important;
        background-color: var(--primary) !important;
        padding: 9px 50px !important;
        height: 40px !important;
        border-radius: 1px !important;
        transition: .5s ease !important;
        box-shadow: 0 0 0 0 var(--primary-glow) !important;
        position: relative;
        margin-bottom: 21px;
        font-family: "Heebo", sans-serif !important;
        text-decoration: none !important;
        font-weight: 800 !important;
    }

        .nlt-button:hover {
            background-color: var(--primary-hover) !important;
            box-shadow: 0 0 20px -8px var(--primary-glow) !important;
    }

    .nlt-button-team {
        text-align: left !important;
        text-transform: capitalize !important;
        font-size: 16px!important;
        color: #fff !important;
        background-color: var(--primary) !important;
        padding: 9px 50px !important;
        height: 40px !important;
        border-radius: 1px !important;
        transition: .5s ease !important;
        box-shadow: 0 24px 20px -16px #0000005c !important;
        position: relative;
        bottom: 0px;
        margin-bottom: 21px;
        font-family: "Heebo", sans-serif !important;
        text-decoration: none !important;
        font-weight: 800 !important;
    
    }

        .nlt-button-team:hover {
            color: var(--primary-text-hover) !important;
            background-color: var(--primary-hover) !important;
            box-shadow: 0 24px 25px -16px #0000005c !important;
            bottom: 0;
    }

    .nlt-button-white {
        text-align: left !important;
        text-transform: capitalize !important;
        font-size: 16px!important;
        color: var(--secondary-text) !important;
        background-color: var(--secondary) !important;
        padding: 9px 50px !important;
        height: 40px !important;
        border-radius: 1px !important;
        transition: .5s ease !important;
        box-shadow: 0 24px 20px -16px var(--secondary-shadow) !important;
        position: relative;
        bottom: 0px;
        margin-bottom: 21px;
        font-family: "Heebo", sans-serif !important;
        text-decoration: none !important;
        font-weight: 800 !important;
    }

        .nlt-button-white:hover {
            color: var(--secondary-text-hover) !important;
            background-color: var(--secondary-hover) !important;
            box-shadow: 0 0 25px -16px var(--secondary-shadow) !important;
            bottom: 0;

    }

    .nlt-section {
        margin-bottom: 75px !important;
    }

    .nlt-team-tile {
        background-color: #ffffff0a !important;
        padding: 0px 8px 0px 8px !important;
        box-shadow: 0px 50px 50px -50px #00000091;
        margin-bottom: 90px; 
    }

    .nlt-team-section-top {
        display: flex;
        padding: 18px 0px 0px 10px !important;
    }

    .nlt-team-section-top-left {
        display: flex;
        width: 50%;
    }

    .nlt-team-content {
        margin-top: 24px;
        text-align: left;
    }

    .nlt-team-divider {
        height: 14px;
    }

    .nlt-team-section-top-right {
        width: 50%;
        padding: 10px 8px 12px 8px !important;
    }

    .nlt-team-section-button {
        padding: 14px 0px 6px 0px !important;
        display: flex;
    }

    .nlt-team-section-button-divider {
        width: 1%;
    }

    .nlt-team-picture {
        height: 100px !important;
        width: 100px !important;
        margin-right: 24px;
        box-shadow: 0px 32px 31px -24px #00000030;
        bottom: 42px;
        position: relative;
        filter: grayscale(100%);
    }

    .nlt-team-tile-member-name {
        color: #ffffff;
        font-family: "Kotori Rose Bold";
        line-height: 0;
        margin-bottom: 0px;
        font-weight: 600;
        font-size: 18px;
        letter-spacing: -0.5px;
    }

    .nlt-team-tile-text {
        text-align: left;
        font-weight: 500;
        line-height: 20px;
        color: #ffffffad;
        font-size: 14px;
    }

    .nlt-cta-container {
        background-color: red !important;
        margin: 0 5%;
        display: flex;
    }

    .nlt-cta {
        background-color: #ef354f !important;
        padding: 32px 16px 36px 32px !important;
        box-shadow: 0px 50px 50px -50px #00000091;
        margin-top: 100px;
        text-align: left !important;
    }

    .nlt-cta-text {
        font-family: "Kotori Rose Bold";
        text-align: left !important;
        font-size: 28px;
        line-height: 36px;
        font-weight: bolder;
        color: #ffffff;
        margin-bottom: 24px !important;
    }

    .nlt-cta-button {
            text-align: left !important;
            text-transform: capitalize !important;
            font-size: 16px!important;
            color: var(--secondary-text) !important;
            background-color: var(--secondary) !important;
            padding: 7px 50px !important;
            height: 40px !important;
            border-radius: 1px !important;
            transition: .5s ease !important;
            box-shadow: 0 24px 20px -16px var(--secondary-shadow) !important;
            position: relative;
            bottom: 0px;
            font-family: "Heebo", sans-serif;
            text-decoration: none !important;
            font-weight: 800;
        }
    
            .nlt-cta-button:hover {
                color: var(--secondary-text-hover) !important;
                background-color: var(--secondary-hover) !important;
                box-shadow: 0 0 25px -16px var(--secondary-shadow) !important;
                bottom: 0;
    
        }  

    .nlt-cta-button-copied {
        text-align: left !important;
        text-transform: capitalize !important;
        font-size: 16px!important;
        color: var(--secondary-text) !important;
        background-color: var(--secondary) !important;
        padding: 7px 50px !important;
        height: 40px !important;
        border-radius: 1px !important;
        transition: .5s ease !important;
        box-shadow: 0 24px 20px -16px var(--secondary-shadow) !important;
        position: relative;
        bottom: 0px;
        font-family: "Heebo", sans-serif;
        text-decoration: none !important;
        font-weight: 800;
    }

    .nlt-cta-button-copied:visited {
        border: none !important;
        border-width: 0 !important;
        border-style: none !important;
    }
    
    .nlt-cta-button-copied:active {
        border: none !important;
        border-width: 0 !important;
        border-style: none !important;
    }
    
    .nlt-cta-button-copied:focus {
        border: none !important;
        border-width: 0 !important;
        border-style: none !important;
    }
}



@media (max-width: 760px) {

    .nlt-container {
        padding: 0 8%;
        background-color: rgba(0, 0, 0, 0);
    }

    .nlt-container-white {
        padding: 0 8%;
        background-color: rgb(253, 253, 253);
    }

    .nlt-container-primary {
        padding: 0 8%;
        margin-top: 4px;
        background-color: rgb(239, 53, 79);
    }

    .nlt-team-container {
        display: block;
    }

    .nlt-team-member {
        display: inline-grid;
        margin-bottom: 0 !important;
        width: 180px;
    }

    .nlt-team-member-name {
        color: #171923;
        line-height: 1;
        margin-bottom: 0;
        font-family: sans-serif;
        font-weight: 600;
        font-size: 16px;
        letter-spacing: 0.1px;
    }

    .nlt-team-member-title {
        color: var(--primary);
        font-weight: bolder;
        font-family: sans-serif;
        line-height: 24px;
        font-size: 17px;
    }

    .nlt-title {
        font-family: "Kotori Rose Bold";
        text-align: left !important;
        font-size: 28px;
        line-height: 36px;
        font-weight: bolder;
        color: #ffffff;
        margin-bottom: 24px !important;
    }

    .nlt-title-black {
        color: #161616;
        font-family: "Kotori Rose Bold";
        text-align: left !important;
        font-size: 28px;
        line-height: 36px;
        font-weight: bolder;
        margin-bottom: 24px !important;
    }

    .nlt-content {
        text-align: left !important;
        font-size: 13px;
        line-height: 17px;
        font-weight: 500;
        letter-spacing: 0.2px;
        color: white !important;
        margin-bottom: 56px !important;
    }

    .nlt-content-black {
        color: #161616 !important;
        text-align: left !important;
        font-size: 13px;
        line-height: 17px;
        font-weight: 500;
        letter-spacing: 0.2px;
        margin-bottom: 42px !important;
    }

    .nlt-align {
        text-align: left !important;
    }

    .nlt-button {
        text-align: left !important;
        text-transform: capitalize !important;
        font-size: 16px!important;
        color: #161822 !important;
        background-color: var(--primary) !important;
        padding: 7px 50px !important;
        height: 50px !important;
    align-items: center !important;
        border-radius: 1px !important;
        transition: .5s ease !important;
        box-shadow: 0 0 0 0 var(--primary-glow) !important;
        position: relative;
        font-family: "Heebo", sans-serif !important;
        text-decoration: none !important;
        font-weight: 800 !important;
    }

        .nlt-button:hover {
            background-color: var(--primary-touch) !important;
    }

        .nlt-button-white:active {
            background-color: var(--primary-touch) !important;
    }

    .nlt-button-white {
        text-align: left !important;
        text-transform: capitalize !important;
        font-size: 16px!important;
        color: rgb(239, 53, 79) !important;
        background-color: #ffffff !important;
        padding: 7px 50px !important;
        height: 40px !important;
        border-radius: 1px !important;
        transition: .5s ease !important;
        box-shadow: 0 0 0 0 #f1f1f1 !important;
        position: relative;
        font-family: "Heebo", sans-serif !important;
        text-decoration: none !important;
        font-weight: 800 !important;
    }

        .nlt-button-white:hover {
            background-color: var(--secondary-touch) !important;
    }

        .nlt-button-white:active {
            background-color: var(--secondary-touch) !important;
    }

    .nlt-button-team {
        text-align: left !important;
        text-transform: capitalize !important;
        font-size: 16px!important;
        color: #fff !important;
        background-color: var(--primary) !important;
        padding: 7px 50px !important;
        height: 40px !important;
        border-radius: 1px !important;
        transition: .5s ease !important;
        box-shadow: 0 0 0 0 #f1f1f1 !important;
        position: relative;
        font-family: "Heebo", sans-serif !important;
        text-decoration: none !important;
        font-weight: 800 !important;
    }

        .nlt-button-team:hover {
            background-color: var(--primary-touch) !important;
    }
        .nlt-button-team:active {
            background-color: var(--primary-touch) !important;
    }

    .nlt-section {
        margin-bottom: 75px !important;
    }

    .nlt-team-tile {
        background-color: #ffffff0a !important;
        padding: 0px 18px 22px 8px !important;
        box-shadow: 0px 50px 50px -50px #00000091;
        margin-bottom: 70px; 
    }

    .nlt-team-section-top {
        padding: 18px 0px 0px 10px !important;
    }

    .nlt-team-section-top-left {
        width: 100%;
    }

    .nlt-team-content {
        margin-top: 24px;
        text-align: left;
    }

    .nlt-team-divider {
        height: 14px;
    }

    .nlt-team-section-top-right {
        width: 100%;
    }

    .nlt-team-section-button {
        padding: 24px 0px 6px 0px !important;
    }

    .nlt-team-section-button-divider {
        height: 10px;
    }

    .nlt-team-picture {
        height: 100px !important;
        width: 100px !important;
        margin-right: 24px;
        box-shadow: 0px 32px 31px -24px #00000030;
        bottom: 42px;
        position: relative;
        filter: grayscale(100%);
    }

    .nlt-team-tile-member-name {
        color: #ffffff;
        font-family: "Kotori Rose Bold";
        line-height: 0;
        margin-bottom: 0px;
        font-weight: 600;
        font-size: 18px;
        letter-spacing: -0.5px;
        margin: -100px 0px 0px 115px !important;
    }

    .nlt-team-tile-member-title {
        color: var(--primary);
        font-weight: bolder;
        font-family: sans-serif;
        line-height: 24px;
        font-size: 16px;
        margin-left: 114px !important;
    }

    .nlt-team-tile-text {
        text-align: left;
        font-weight: 500;
        line-height: 20px;
        color: #ffffffad;
        font-size: 14px;
    }

    .nlt-cta-container {
        background-color: red !important;
        margin: 0 5%;
        display: flex;
    }

    .nlt-cta {
        background-color: #ef354f !important;
        padding: 32px 16px 36px 32px !important;
        box-shadow: 0px 50px 50px -50px #00000091;
        margin-top: 100px;
        text-align: left !important;
    }

    .nlt-cta-text {
        font-family: "Kotori Rose Bold";
        text-align: left !important;
        font-size: 28px;
        line-height: 36px;
        font-weight: bolder;
        color: #ffffff;
        margin-bottom: 24px !important;
    }

    .nlt-cta-button {
            text-align: left !important;
            text-transform: capitalize !important;
            font-size: 16px!important;
            color: var(--secondary-text) !important;
            background-color: var(--secondary) !important;
            padding: 7px 50px !important;
            height: 40px !important;
            border-radius: 1px !important;
            transition: .5s ease !important;
            box-shadow: 0 24px 20px -16px var(--secondary-shadow) !important;
            position: relative;
            bottom: 0px;
            font-family: "Heebo", sans-serif;
            text-decoration: none !important;
            font-weight: 800;
        }
    
            .nlt-cta-button:hover {
                color: var(--secondary-text-hover) !important;
                background-color: var(--secondary-hover) !important;
                box-shadow: 0 0 25px -16px var(--secondary-shadow) !important;
                bottom: 0;
    
        }

    .nlt-cta-button-copied {
        text-align: left !important;
        text-transform: capitalize !important;
        font-size: 16px!important;
        color: var(--secondary-text) !important;
        background-color: var(--secondary) !important;
        padding: 7px 50px !important;
        height: 40px !important;
        border-radius: 1px !important;
        transition: .5s ease !important;
        box-shadow: 0 24px 20px -16px var(--secondary-shadow) !important;
        position: relative;
        bottom: 0px;
        font-family: "Heebo", sans-serif;
        text-decoration: none !important;
        font-weight: 800;
    }

    .nlt-cta-button-copied:visited {
        border: none !important;
        border-width: 0 !important;
        border-style: none !important;
    }
    
    .nlt-cta-button-copied:active {
        border: none !important;
        border-width: 0 !important;
        border-style: none !important;
    }
    
    .nlt-cta-button-copied:focus {
        border: none !important;
        border-width: 0 !important;
        border-style: none !important;
    }

}


/* AJUSTEMENT DES CLASSES EXISTANTES */

.button {
    text-decoration: none !important;
    text-transform: none !important;
    transition: .2s ease !important;
    border: none !important;
    border-width: 0 !important;
    border-style: none !important;
    font-family: "Heebo", sans-serif !important;
}

.button:visited {
    border: none !important;
    border-width: 0 !important;
    border-style: none !important;
}

.button:active {
    border: none !important;
    border-width: 0 !important;
    border-style: none !important;
}

.button:focus {
    border: none !important;
    border-width: 0 !important;
    border-style: none !important;
}

.body {
    background-color: #1b1e23 !important;
}

@media (max-width: 760px) {

    .section-inner {
        padding-top: 60px !important;
        padding-bottom: 20px !important;
    }

    .button-wide-mobile {
        max-width: 100% !important;
    }
}

@media (min-width: 761px) {

    .section-inner {
        padding-top: 70px !important;
        padding-bottom: 36px !important;
    }
}